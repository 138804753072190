body {
  margin: 0;
  font-family: "Mona-Sans", "Poppins", sans-serif;

  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  text-size-adjust: 100%;
  padding-right: 0 !important;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;*/
}
* {
  box-sizing: border-box;
}
