.App {
  text-align: center;
  color: #040a1e;
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Mona-Sans";
  src: url(./fonts/Mona-Sans/Mona-Sans-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  src: url(./fonts/Mona-Sans/Mona-Sans-RegularItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: condensed;
  src: url(./fonts/Mona-Sans/Mona-Sans-RegularNarrow.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-stretch: condensed;
  src: url(./fonts/Mona-Sans/Mona-Sans-RegularNarrowItalic.otf)
    format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  src: url(./fonts/Mona-Sans/Mona-Sans-RegularWide.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-style: italic;
  src: url(./fonts/Mona-Sans/Mona-Sans-RegularWideItalic.otf) format("opentype");
}

@font-face {
  font-family: "Mona-Sans";
  font-weight: 900;
  src: url(./fonts/Mona-Sans/Mona-Sans-Black.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-weight: 900;
  src: url(./fonts/Mona-Sans/Mona-Sans-BlackItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: condensed;
  font-weight: 900;
  src: url(./fonts/Mona-Sans/Mona-Sans-BlackNarrow.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-stretch: condensed;
  font-weight: 900;
  src: url(./fonts/Mona-Sans/Mona-Sans-BlackNarrowItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-weight: 900;
  src: url(./fonts/Mona-Sans/Mona-Sans-BlackWide.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-style: italic;
  font-weight: 900;
  src: url(./fonts/Mona-Sans/Mona-Sans-BlackWideItalic.otf) format("opentype");
}

@font-face {
  font-family: "Mona-Sans";
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-BoldItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: condensed;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-BoldNarrow.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-stretch: condensed;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-BoldNarrowItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-BoldWide.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-style: italic;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-BoldWideItalic.otf) format("opentype");
}

@font-face {
  font-family: "Mona-Sans";
  font-weight: 800;
  src: url(./fonts/Mona-Sans/Mona-Sans-ExtraBold.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-weight: 800;
  src: url(./fonts/Mona-Sans/Mona-Sans-ExtraBoldItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: condensed;
  font-weight: 800;
  src: url(./fonts/Mona-Sans/Mona-Sans-ExtraBoldNarrow.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-stretch: condensed;
  font-weight: 800;
  src: url(./fonts/Mona-Sans/Mona-Sans-ExtraBoldNarrowItalic.otf)
    format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-weight: 800;
  src: url(./fonts/Mona-Sans/Mona-Sans-ExtraBoldWide.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-style: italic;
  font-weight: 800;
  src: url(./fonts/Mona-Sans/Mona-Sans-ExtraBoldWideItalic.otf)
    format("opentype");
}

@font-face {
  font-family: "Mona-Sans";
  font-weight: 300;
  src: url(./fonts/Mona-Sans/Mona-Sans-Light.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-weight: 300;
  src: url(./fonts/Mona-Sans/Mona-Sans-LightItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: condensed;
  font-weight: 300;
  src: url(./fonts/Mona-Sans/Mona-Sans-LightNarrow.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-stretch: condensed;
  font-weight: 300;
  src: url(./fonts/Mona-Sans/Mona-Sans-LightNarrowItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-weight: 300;
  src: url(./fonts/Mona-Sans/Mona-Sans-LightWide.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-style: italic;
  font-weight: 300;
  src: url(./fonts/Mona-Sans/Mona-Sans-LightWideItalic.otf) format("opentype");
}

@font-face {
  font-family: "Mona-Sans";
  font-weight: 500;
  src: url(./fonts/Mona-Sans/Mona-Sans-Medium.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-weight: 500;
  src: url(./fonts/Mona-Sans/Mona-Sans-MediumItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: condensed;
  font-weight: 500;
  src: url(./fonts/Mona-Sans/Mona-Sans-MediumNarrow.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-stretch: condensed;
  font-weight: 500;
  src: url(./fonts/Mona-Sans/Mona-Sans-MediumNarrowItalic.otf)
    format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-weight: 500;
  src: url(./fonts/Mona-Sans/Mona-Sans-MediumWide.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-style: italic;
  font-weight: 500;
  src: url(./fonts/Mona-Sans/Mona-Sans-MediumWideItalic.otf) format("opentype");
}

@font-face {
  font-family: "Mona-Sans";
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-SemiBold.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-SemiBoldItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: condensed;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-SemiBoldNarrow.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-stretch: condensed;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-SemiBoldNarrowItalic.otf)
    format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-SemiBoldWide.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-style: italic;
  font-weight: 700;
  src: url(./fonts/Mona-Sans/Mona-Sans-SemiBoldWideItalic.otf)
    format("opentype");
}

@font-face {
  font-family: "Mona-Sans";
  font-weight: 200;
  src: url(./fonts/Mona-Sans/Mona-Sans-UltraLight.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-weight: 200;
  src: url(./fonts/Mona-Sans/Mona-Sans-UltraLightItalic.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: condensed;
  font-weight: 200;
  src: url(./fonts/Mona-Sans/Mona-Sans-UltraLightNarrow.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-style: italic;
  font-stretch: condensed;
  font-weight: 200;
  src: url(./fonts/Mona-Sans/Mona-Sans-UltraLightNarrowItalic.otf)
    format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-weight: 200;
  src: url(./fonts/Mona-Sans/Mona-Sans-UltraLightWide.otf) format("opentype");
}
@font-face {
  font-family: "Mona-Sans";
  font-stretch: expanded;
  font-style: italic;
  font-weight: 200;
  src: url(./fonts/Mona-Sans/Mona-Sans-UltraLightWideItalic.otf)
    format("opentype");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
